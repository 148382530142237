<template>
  <AppCard :size="'2xl'">
    <template #header>
      <AppCardHeader>
        <template #left>
          <AppButton theme="icon" @click="goToPendingActions">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
          <div>
            <div class="title-small">
              Pending Actions <span role="img" aria-label="clock">⏰</span>
            </div>
            <div class="title">Questions</div>
          </div>
        </template>
      </AppCardHeader>
    </template>
    <AppDynamicForm :fields="formFields" />

    <form class="footer-right" @submit.prevent="submit">
      <AppCardFooter class="z-50">
        <AppButton type="submit" :disabled="isSubmitButtonDisabled">
          {{ 'Save & Continue' }}
        </AppButton>
      </AppCardFooter>
    </form>
    <RouterView />
  </AppCard>
</template>

<script>
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import { computed, watch, ref } from '@vue/composition-api'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import { useAdditionalInformationForm } from '@/forms/DashboardAdditionalInformationForm'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'

export default {
  components: {
    AppCard,
    AppCardHeader,
    IconChevronLeft,
    AppIcon,
    AppButton,
    AppCardFooter,
    AppDynamicForm,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()

    const isSetupFinished = ref(false)
    const isCustomFieldInvalid = ref(false)
    const additionalInformationStatus = computed(() =>
      dashboardStore.getIsAdditionalInformationStatusCompleted('questionsAndAnswers')
    )
    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useAdditionalInformationForm(store)
    const additionalInformation = computed(() => dashboardStore.additionalInformation)

    if (additionalInformation?.value?.fields?.length > 0) {
      setupFields(additionalInformation.value)
      isSetupFinished.value = true
    }

    watch(additionalInformation, (data) => {
      if (!isSetupFinished.value) {
        setupFields(data)
        isSetupFinished.value = true
      }
    })

    if (!additionalInformationStatus.value) {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: 'Additional Questions',
        },
      })
    }

    formFields.value.forEach((field, i) => {
      if (field.component.name === 'xeInputDropdownCustomComponent') {
        formFields.value[i]['listeners'] = {
          isCustomFieldInvalid: (isInvalid) => (isCustomFieldInvalid.value = isInvalid),
        }
      }
    })

    const submit = async () => {
      await preserveData(formFields)
      dashboardStore.setAdditionalInformationStatus('questionsAndAnswers')
      goToPendingActions()
    }

    const isSubmitButtonDisabled = computed(() => {
      // Validate form fields, and agree all owners checkbox to disabled button
      const disabledButton = Boolean(
        !hasFormFields.value || isFormInvalid.value || isCustomFieldInvalid.value
      )
      return disabledButton
    })

    const triggerEvents = (formFields) => {
      const eventTriggerFields = formFields.value.filter(field => field.id === 'planToUseAccount' || field.id === 'heardAboutUs')

      eventTriggerFields.forEach(field => {
        if (field.id === 'planToUseAccount') {
          let eventProps = {}
          eventProps['products'] = field.value.join(', ')
          field.value.forEach((option) => {
            eventProps[option] = true
          })

          analyticsStore.track({
            event: 'Xe Products Selected',
            traits: eventProps,
          })
        } else if (field.id === 'heardAboutUs') {
          analyticsStore.track({
            event: 'Acquisition source completed',
            traits: { 'source': field.value },
          })
        }
      });
    }

    const preserveData = async (formFields) => {
      const questionsAndAnswers = await formFields.value.map((field) => ({
        id: field.id,
        value: field.value,
        idText: field.label,
      }))
      dashboardStore.questionsAndAnswers = questionsAndAnswers
      triggerEvents(formFields)
    }

    const goToPendingActions = () => {
      router.push({ name: 'PendingActions' })
    }

    return {
      formFields,
      isSubmitButtonDisabled,
      isLoading,
      submit,
      goToPendingActions,
    }
  },
}
</script>

<style lang="postcss" scoped>
.owners-alert {
  @apply bg-tertiary-blue items-start justify-start gap-3 mt-4 !important;
}

.authorized-caption {
  @apply type-body-bold !important;
}

.authorized-caption-initials {
  font-size: 16px !important;
}

.authorized-personnel {
  &-item {
    border-bottom-width: 1px;
    color: rgba(243, 244, 246, 1);
    &:last-child {
      border: unset;
    }
  }
}
.title-small {
  @apply type-subtitle-bold text-text-gray-primary;
  color: #5c667b;
  white-space: nowrap;
}
.title {
  @apply type-h1;
}

.addAuthorizedButton {
  @apply bg-tertiary-gray-darker text-main-black mb-6 mt-6 !important;

  ::v-deep .button-inner {
    @apply type-caption-bold px-4 !important;
  }
}

.card-footer {
  .button {
    ::v-deep&.button--primary {
      .button-inner {
        @apply px-6;
      }
      &:disabled:not(.button--loading) {
        @apply text-white bg-blue-button-disabled !important;
      }
    }
  }

  @screen sm {
    @apply pt-0 !important;
    .button {
      @apply rounded-lg w-full !important;
    }
  }
}

.card-header {
  @apply p-12 !important;
  @apply mb-0 !important;
  >>> .card-header-left {
    .button {
      transform: translateY(-4px);
      background-color: unset;
      .button-inner {
        justify-content: left;
        align-items: baseline;
        .md-ripple-wave {
          display: none;
        }
      }
    }
  }
}

.card {
  max-width: 640px;
  /deep/ .card-content {
    @apply pt-20;
    @apply px-4 pb-12;
    @screen sm {
      @apply pt-0 px-12;
    }
  }
}
.footer-right {
  display: flex;
  justify-content: flex-end;
}
</style>
